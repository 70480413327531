.select {
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  user-select: none;
  background: #333333;
  border: 0.0625rem solid #999999;
  border-radius: 0.625rem;

  &_paginator {
    .select__item {
      width: 100%;
    }
  }

  &_open {
    border-bottom: none;
  }

  &__item {
    width: 100%;
    height: 2.5rem;
    max-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 0.625rem;
    font-size: 0.875rem;
    box-sizing: border-box;
    &-main {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-icon {
      min-width: 1.875rem;
      width: 1.875rem;
      margin-left: 0.625rem;
    }
    &:not(&_current) {
      &:hover {
        background-color: #FFDF1B;
      }
    }
  }

  &__arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    right: 1rem;
    transition: transform 0.3s ease;
  }

  &_color {
    &_grey {
      background: #474747;

    }
    &_transparent {
      background: transparent;
      border: none;
      border-radius: 0;
    }
  }

  &__arrow-svg {
    height: 0.571rem;
    width: 0.857rem;
  }

  &_open &__arrow {
    transform: scaleY(-1);
  }

  &__items-list {
    position: absolute;
    top: 2.5rem;
    border-top: none;
    border-radius: 0 0 0.3125rem 0.3125rem;
    background: #333333;
    width: 100%;
    z-index: 2;
    max-height: 21rem;
    border: 0.0625rem solid rgba(255, 255, 255, 0.3);
    overflow-y: auto;
    overflow-x: hidden;

    &_color {
      &_grey {
        top: 100%;
        border: 0.0625rem solid rgba(255, 255, 255, 0.4);
        background: #474747;
        width: 100%;
      }

      &_transparent {
        border: none;
      }

      &_white {
        background: white;
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 0.5rem;
      scroll-margin-right: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 3px;
      height: 1rem;
      background: #037ac9;
      cursor: pointer;
    }
  }
}
