.users-filter-block {
  display: flex;
  height: 2.5rem;
  display: flex;
  width: 100%;
  position: relative;
  &__input {
    width: 100%;
    min-width: 10rem;
  }
  &__icon {
    & svg {
      width: 1.5rem;
    }
    &-wrapper {
      border: none;
      margin-left: 0.625rem;
      background: #FFDF1B;
      box-shadow: 0px 5px 15px 0px rgba(201, 133, 0, 0.50);
      border-radius: 0.625rem;
      padding: 0.625rem;
      cursor: pointer;
    }
  }
}
