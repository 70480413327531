.table-row-pagination {
  &__tr {
    background: #333333;
    border-radius: 0.625rem;
    height: 3.25rem;
    display: flex;
    padding: 0.625rem 0;
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 0.3125rem;
    }
  }
  &__td {
    width: 20%;
    height: 100%;
    min-width: 10%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 0.875rem;
    padding: 0 1.25rem;
    &:not(:last-child) {
      border-right: 0.0625rem solid rgba(255, 255, 255, 0.3);
    }
    &:first-child {
      justify-content: flex-start;
    }
    &_status {
      &_1 {
        color: #14805E;
      }
      &_2 {
        color: #FFBC0F;
      }
      &_3 {
        color: #d31c32;
      }
    }
  }

  &__arrow {
    width: 1.875rem;
    min-width: 1.875rem;
  }
}
