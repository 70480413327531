.header {
  z-index: 1;
  background: #474747;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.3);
  width: 100%;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;

  &__language-selector {
    margin-right: 0.625rem;
  }

  &_open &__user-arrow {
    transform: scale(-1);
  }

  &__user {
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #333333;
    border-radius: 0.625rem;
    padding: 0.3125rem 0.3125rem 0.3125rem 0.9375rem;

    &-balance {
      color: #fff;
      display: flex;
      align-items: center;
      cursor: pointer;
      background: #333333;
      border-radius: 0.625rem;
      padding: 0.3125rem 0.3125rem 0.3125rem 0.9375rem;
      margin-right: 0.625rem;
    }

    &-balance-info {
      font-weight: 500;
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      text-align: right;
    }

    &-balance-title {
      font-size: 0.75rem;
      font-weight: 600;
      text-transform: capitalize;
    }

    &-info {
      font-weight: 500;
      text-transform: uppercase;
      margin-right: 0.625rem;
      display: flex;
      flex-direction: column;
      text-align: right;
      &-id {
        font-size: 0.75rem;
      }
      &-login {
        font-size: 1rem;
        font-weight: 700;
      }
    }

    &-img {
      width: 1.5rem;
      height: 1.5rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &-arrow {
      width: 1.875rem;
      height: 1.875rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
