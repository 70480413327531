#notify-root {
  position: fixed;
  width: 100%;
  max-width: 20.375rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  z-index: 1001;
  margin: auto;
  flex-direction: column-reverse;
}