.financial-reports-table {
  &__header {
    margin: 1.25rem 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-left {
      font-weight: 700;
      font-size: 1.375rem;
    }
  }
  &__tbody {
    > div {
      &:not(:last-child) {
        margin-bottom: 0.313rem;
      }
    }
  }
  &__no-results {
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.4);
    padding: 1.25rem;
    background: #333333;
    border-radius: 0.625rem;
    width: 100%;
    text-align: center;
  }
  &__table {
    background: #474747;
    border-radius: 1.25rem;
    padding: 1.375rem 1.25rem 1.25rem 1.25rem;
  }

  &__paginator-wrapper {
    margin: 0.9375rem 1.875rem 0 1.25rem;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__extra-info {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  &__page-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 1.875rem;
    &-title {
      margin-right: 0.625rem;
      font-weight: 700;
      font-size: 0.875rem;
      color: #ffffff;
      white-space: nowrap;
    }
    &-select {
      width: 5.3125rem;
    }
  }
}
