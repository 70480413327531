.language-dropdown-selector {
  width: 100%;
  position: relative;
  color: #fff;
  $root: &;

  &_isHeader {
    #{$root}__current {
      border: none;
      background: #333333;
    }

    #{$root}__items {
      border: none;
      background: #333333;
    }
    #{$root}__item {
      &-img {
        margin: 0.9375rem 0 0.9375rem 0.9375rem;
      }
      &-text {
        margin: 0 0 0 0.3125rem;
      }
    }
    #{$root}__current-item-arrow {
      margin-right: 0.625rem;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 0.8125rem;
    margin-bottom: 0.3125rem;
  }

  &__current {
    cursor: pointer;
    height: 2.5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #474747;
    border: 0.0625rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0.625rem;
  }

  &__wrapper {
    display: flex;
  }

  &_open &__current {
    border-radius: 0.625rem 0.625rem 0 0;
  }

  &__current-name {
    display: flex;
    align-items: center;
    margin-right: 1.25rem;
    font-weight: 400;
    font-size: 0.875rem;
    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;
    color: #ffffff;
  }

  &__current-img {
    width: 1.375rem;
    height: 1.375rem;
    margin: 0 0.625rem;
  }

  &__current-item-arrow {
    width: 1.875rem;
    min-width: 1.875rem;
    height: 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: rotate(0deg);
  }

  &_open &__current-item-arrow {
    transform: rotate(180deg);
  }

  &__items {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    top: calc(100%);
    position: absolute;
    z-index: 3;
    left: 0;
    animation: reduceVertical_language-dropdown-selector-mobile 0.3s linear;
    overflow: hidden;
    background: #474747;
    border: 0.0625rem solid rgba(255, 255, 255, 0.3);
    border-radius: 0 0 0.625rem 0.625rem;
  }

  &_open &__items {
    animation: incraseVertical_language-dropdown-selector-mobile 0.3s linear forwards;
  }

  &__item {
    cursor: pointer;
    height: 2.25rem;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__item-img {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 0.625rem;
  }

  &__item-text {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.875rem;
    margin-right: 1.25rem;
    text-align: left;
    font-feature-settings: "pnum" on, "lnum" on;
  }
  &__item_selected &__item-text {
    font-weight: 600;
  }
  &__item_selected {
    background: #FFDF1B;
  }

  @keyframes incraseVertical_language-dropdown-selector-mobile {
    0% {
      opacity: 0;
      max-height: 0rem;
    }
    100% {
      opacity: 1;
      max-height: 18.75rem;
    }
  }

  @keyframes reduceVertical_language-dropdown-selector-mobile {
    0% {
      opacity: 1;
      max-height: 18.75rem;
    }
    100% {
      opacity: 0;
      max-height: 18.75rem;
    }
  }

  @keyframes darker_language-dropdown-selector-mobile {
    0% {
      background: transparent;
    }
    100% {
      background: #0f182f;
    }
  }

  @keyframes lighter_language-dropdown-selector-mobile {
    0% {
      background: #0f182f;
    }
    100% {
      background: transparent;
    }
  }
}
