.users {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 1.25rem;
  min-width: fit-content;

  &__center {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: center;
    &-controller {
      display: grid;
      justify-content: flex-end;
      grid-template-columns: auto auto;
      margin-left: 1.875rem;
    }
  }

  &__page-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 1.875rem;
    &-title {
      margin-right: 0.625rem;
      font-weight: 700;
      font-size: 0.875rem;
      color: #ffffff;
      white-space: nowrap;
    }
    &-select {
      width: 5.3125rem;
    }
  }
}
