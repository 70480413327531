.tabs {
  display: flex;
  width: 100%;
  justify-content: flex-start;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2.5rem 0 1.875rem;
    height: 3.75rem;
    width: 100%;
    //max-width: 10rem;
    cursor: pointer;
    border-radius: 1.25rem 1.25rem 0 0;
    background-color: transparent;

    &-text {
      color: #fff;
      font-weight: 700;
      font-size: 0.875rem;
      width: max-content;
    }

    &-icon {
      width: 1.5rem;
      min-width: 1.5rem;
      margin-right: 0.625rem;
    }
    &_active {
      background: #474747;
    }
  }
}
