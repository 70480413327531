.days-filter {
  display: flex;
  $root: &;
  margin-top: 0.875rem;

  &__item {
    padding: 0.9375rem 1.875rem;
    font-weight: 700;
    font-size: 0.875rem;
    background: #333333;
    border-radius: 0.625rem;
    cursor: pointer;
    max-height: 2.875rem;
    white-space: nowrap;
    #{$root}_isMobile & {
      width: 100%;
      background: #3b3b3d;
      font-size: 0.75rem;
      text-align: center;
    }
    &:not(:last-child) {
      margin-right: 0.625rem;
    }
    &_active {
      background: #FFDF1B;
    }

    &:hover {
      background: #FFDF1B;
    }
  }
}
