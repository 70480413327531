.sidebar {
  background: #474747;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 17.5rem;
  padding: 1.25rem;
  z-index: 9999;
  overflow: hidden;
  width: 20%;

  &__menu {
    overflow: auto;
  }

  &__logo {
    max-width: 12.875rem;
    width: 100%;
    margin-bottom: 0.625rem;
    &-wrapper {
      display: flex;
      justify-content: center;
    }
    &_isMobile {
      max-width: 12.875rem;
      margin: 3.1875rem 0;
    }
  }
}
