.change-password-users {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;

  &__top {
    width: 100%;
  }

  &__header {
    font-weight: 500;
    font-size: 1.875rem;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 3.125rem;
  }

  &__fields {
    max-width: 18.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &__form {
    background: #474747;
    border-radius: 1.25rem;
    padding: 3.125rem 0 4.6875rem;
    max-width: 48.125rem;
    width: 100%;
    transform: translateY(13vh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__input-block {
      width: 100%;
      margin-bottom: 0.625rem;
      &-title {
        font-weight: 500;
        font-size: 0.75rem;
        margin-bottom: 0.125rem;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5rem;
    width: 100%;
    padding-right: 4.375rem;
    button {
      width: fit-content;
      padding: 0.7188rem 1.25rem;
      text-transform: uppercase;
      margin-left: 1.25rem;
    }
  }

  &__user-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.625rem 1.375rem 0.625rem 0.625rem;
    background: #333333;
    margin-bottom: 1.875rem;
    border-radius: 0.625rem;
    &-right {
      & span {
        display: block;
        text-align: right;
        &:first-child {
          font-weight: 500;
          font-size: 0.75rem;
          color: rgba(255, 255, 255, 0.4);
          text-align: left;
        }
        &:last-child {
          font-weight: 700;
          font-size: 0.875rem;
        }
      }
    }
    &-left {
      display: flex;
      align-items: center;
    }
    &-icon {
      display: flex;
      width: 1.5rem;
      min-width: 1.5rem;
      margin-right: 1.25rem;
    }
    &-info {
      font-weight: 700;
      font-size: 0.875rem;
      &-type {
        font-size: 0.75rem;
        text-transform: uppercase;
        padding: 0.1875rem 0.375rem;
        border-radius: 0.3125rem;
        background: #FFBC0F;
        width: fit-content;
        margin-top: 0.3125rem;
        &_role_6 {
          background: #14805E;
        }
      }
    }
  }
}
