.table-head-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.0625rem;
  box-sizing: border-box;

  &__table-head {
    width: 20%;
    font-weight: 700;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    &:first-child {
      padding-left: 0.625rem;
      text-align: left;
    }
  }
}
